.disclaimer {
    font-family: "Source Sans Pro", "Segoe UI", "Helvetica Neue", Arial;
    color: #101820;
    font-size: 0.95em;
    font-weight: 500;
    line-height: 1.6em;
    text-align: left;
    text-size-adjust: 100%;
    text-decoration: none;
    font-style: italic;
}

.fullDisclaimer {
    font-family: "Source Sans Pro", "Segoe UI", "Helvetica Neue", Arial;
    color: #101820;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.6em;
    text-align: left;
    text-size-adjust: 100%;
    text-decoration: none;
}