:root {
    --dangerRed: '#ea2f65';
    --commonFont: "Source Sans Pro", "Segoe UI", "Helvetica Neue", "Arial";
}

.category {
    font-size: 1rem;
    font-weight: 700;
    color: #000000; 
    text-decoration: none;
    text-transform: uppercase;
    font-family: var(--commonFont);
}

.sources {
    font-size: 1.5rem;
    color: #000000;
    font-family: "sans-serif", Georgia;
    font-weight: 500;
    margin-block-end: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

.source {
    color: #000000;
    font-family: "sans-serif", Georgia;
    font-size: 1.25rem;
    font-weight: 400;
    cursor: pointer;
    text-decoration-style: solid;
    overflow-wrap: break-word;
}

.callToAction {
    color: #000000;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: "Source Sans Pro", "Segoe UI", "Helvetica Neue", Arial;
    line-height: 1.5rem;
    text-align: left;
    padding-top: 1rem;
}

.btnDark, .btnDark:hover, .btnDark:focus {
    background-color: #000000;
    color:#FEE715;
    margin-top: 1.5em;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    padding: 0.25rem 1rem;
    font-weight: 400;
}