.helperRow {
    align-items: center !important;
    justify-content: space-between !important;
    display: flex;
    flex-wrap: wrap;
}

.helperCol {
    flex-basis: 0;
    flex-grow: 1;
}

.button {
    display: block;
    width: 100%;
    font-family: "Source Sans Pro", "Segoe UI", "Helvetica Neue", Arial;
    position: relative;
    background-color: #101820;
    color: #FEE715 !important;
    border: 1px #101820 solid;
    padding: 0.65rem 2rem;
    font-size: 1.15rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    cursor: pointer;
    font-weight: 400;
    text-align: center;
}