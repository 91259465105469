.navbar {
    box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.05);
    transition: all .08s;
    font-weight: 400;
    background-color: #101820;
    padding: 0em;
    position:sticky;
    top: 0;
    right: 0;
    left: 0;
    align-items: center;
    padding: 0.5rem 1rem;
    display: flex;
    box-sizing: border-box;
    font-family: "Source Sans Pro", "Segoe UI", "Helvetica Neue", "Arial";
    font-size: 1rem;
    line-height: 1.6;
    color: #FEE715;
    justify-content: flex-start;
    text-align: left;
    flex-direction: row;
    flex-wrap: nowrap;
    transition-delay: 0s;
    transition-duration: 0.08s;
    transition-property: all;
    transition-timing-function: ease;
    z-index: 2000;
}

.container {
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

.navbarBrand {
    font-size: 1.25rem;
    font-family: "Playfair Display", "Segoe UI", "Helvetica Neue", "Arial";
    display: inline-block;
    padding-top: 0.68rem;
    padding-bottom: 0.68rem;
    line-height: inherit;
    white-space: nowrap;
}

.topImage {
    width: 150px !important;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
}

.listItemAlign {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    list-style: none;
    box-sizing: border-box;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    align-items: center;
    list-style-position: outside;
}

.buttonAlign {
    background-color: #101820;
    border-style: solid;
    box-sizing: border-box;
    border-top-style: solid;
    border-color: #FEE715;
    text-decoration-color: #FEE715;
    color: #FEE715;
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 0.9rem;
}