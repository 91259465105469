.footer {
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
}

.text {
    display: block;
    color: #101820;
    text-align: center;
    font-family: "Source Sans Pro", "Segoe UI", "Helvetica Neue", "Arial";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
}

.img {
    width: 15rem;
    cursor: pointer;
    overflow-clip-margin: content-box;
    overflow: clip;
    vertical-align: middle;
    border-style: none;
}